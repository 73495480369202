import React from 'react';

import { NewsletterComponent as NewsletterComponentType } from '@AuroraTypes';
import Newsletter from '@Components/Newsletter/Newsletter';

export const NewsletterComponent: React.FC<NewsletterComponentType> = ({ newsletter }) => (
  <Newsletter
    socialProfiles={newsletter.socialProfiles}
    emailRegex={newsletter.emailRegex}
  />
);
